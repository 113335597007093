<template>
  <AppLayout clean>
    <h1 class="mb-5">
      AGB Werbung &copy; Vermarktung: Allgemeine Bestimmungen
    </h1>

    <ol>
      <li class="agb-title">
        Vertragsgegenstand &amp; Anwendungsbereich
        <ol>
          <li>
            Der Welt der Wunder Sendebetrieb GmbH, Hofmannstraße 51, 81379
            München bietet die nachfolgend beschriebenen Werbe- und
            Vermarktungsleistungen im eigenen Namen und auf eigene Rechnung
            an.<br />
            Welt der Wunder bedient sich zur Erfüllung der vertraglichen
            Leistungen nach eigenem Ermessen Dritter.
          </li>
          <li>
            Diese allgemeinen Geschäftsbedingungen für Werbe- und
            Vermarktungsleistungen der Welt der Wunder Sendebetrieb GmbH
            (nachfolgend „diese Geschäftsbedingungen“) finden Anwendung aus
            sämtliche Vertragsverhältnisse über die Erbringung der hierin
            beschriebenen Leistungen.
          </li>
          <li>
            Allgemeine Geschäftsbedingungen des Vertragspartners der Welt der
            Wunder Sendebetrieb GmbH (nachfolgend „Kunde“ finden keine Anwendung
            und einer Einbeziehung wird bereits im Voraus ausdrücklich
            widersprochen.
          </li>
          <li>
            Die Werbemittel des Kunden müssen den anwendbaren gesetzlichen
            Vorgaben besprechen.
          </li>
          <li>
            Nachträgliche Änderungsvereinbarungen zu diesen Geschäftsbedingungen
            bedürfen der Schriftform. Individuelle Vereinbarungen mit dem Kunden
            gehen diesen Geschäftsbedingungen vor.
          </li>
          <li>
            Welt der Wunder ist berechtigt, diese Geschäftsbedingungen jederzeit
            mit Wirkung für zukünftige Vertragsverhältnisse anzupassen. Es wird
            immer die bei Vertragsabschluss vorgelegte und unter
            <a href="/" target="_blank">www.weltderwunder.de</a> veröffentlichte
            Version der Geschäftsbedingungen einbezogen.
          </li>
          <li>
            Welt der Wunder ist weiterhin berechtigt, diese Geschäftsbedingungen
            einseitig durch Mitteilung in Textform aufgrund von
            Gesetzesänderungen, Rundfunk- und medienaufsichtsrechtlichen
            Maßnahmen oder wegen Änderung technischer Standards, zu deren
            Umsetzung Welt der Wunder rechtlich verpflichtet ist, nachträglich
            unter Berücksichtigung der Interessen des Kunden zu ändern. Die
            Änderung wird 6 Wochen nach Zugang der Mitteilung wirksam, soweit
            der Kunde der Änderung nicht widerspricht.
          </li>
          <li>
            Welt der Wunder ist darüber hinaus berechtigt, de
            Geschäftsbedingungen einseitig durch Benachrichtigung über die
            Änderung(en) an diesen Geschäftsbedingungen und Zusendung einer
            ergänzten Version dieser Geschäftsbedingungen in Textform zu ändern.
            Die Änderung wird 6 Wochen nach Zugang der Mitteilung mit den
            ergänzten Bestimmungen wirksam, sofern der Kunde der Änderung nicht
            widerspricht. Welt der Wunder wird den Kunden in der Mitteilung
            darauf hinweisen, dass die fortgesetzte Nutzung von Leistungen ohne
            Widerspruch gegen die Änderungen über 6 Wochen nach Zugang der
            Mitteilung hinaus die Annahme der Änderungen bedeutet. Wenn der
            Kunde den Änderungen widerspricht, sind beide berechtigt die
            bestehenden Verträge mit einer Frist von 6 Wochen zu kündigen.
          </li>
          <li>
            Für Product Placement gelten die gesetzlichen Richtlinien, sofern
            keine Einzelvereinbarungen geschlossen worden.
          </li>
          <li>
            Zusätzlich gelten die sonstigen gesetzlichen werberelevanten
            Richtlinien.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Vertragsabschluss
        <ol>
          <li>
            Angebote seitens Welt der Wunder, egal ob schriftlich, mündlich oder
            in Textform sind vorbehaltlich einer anderen abweichenden
            Vereinbarung oder Äußerung unverbindlich und stellen lediglich eine
            Aufforderung zur Abgabe eines Angebots des Kunden dar.
          </li>
          <li>
            Ein Vertragsabschluss kommt im Fall eines Angebots des Kunden durch
            eine Auftragsbestätigung seitens Welt der Wunder in Textform zu
            Stande; alternativ kann ein Angebot auch durch Umsetzung des
            Auftrags angenommen werden. Bei einer Buchung über ein
            elektronisches Buchungssystem übersendet Welt der Wunder entweder
            eine elektronische Buchungsbestätigung oder akzeptiert die Buchung
            durch eine entsprechende Statusänderung in dem vom Kunden
            einsehbaren Buchungssystem.
          </li>
          <li>
            Soweit Agenturen für Dritte Werbeaufträge erteilen, so ist Welt der
            Wunder berechtigt, die Ausführung zu verweigern, bis die Agentur den
            Auftraggeber namentlich benennt. Welt der Wunder ist berechtigt, von
            Agenturen einen Nachweis der Beauftragung zu verlangen und bis zur
            Erbringung des Nachweises die eigenen Leistungen zurückzuhalten.
            Welt der Wunder behält sich das Recht vor, den Auftraggeber der
            Agentur direkt zu kontaktieren und diesem die Buchungsbestätigung
            vorzulegen.
          </li>
          <li>
            Soweit Agenturen nicht ausdrücklich als Vertreter des Auftraggebers
            mit entsprechenden Vollmachtsnachweis handeln, erfolgt die
            Abrechnung gegenüber der Agentur als Schuldner. Im Falle einer
            Abrechnung gegenüber der Agentur tritt die Agentur bereits bei
            Vertragsabschluss etwaige Zahlungsansprüche aus der jeweiligen
            Buchung gegenüber<br />
            ihrem Auftraggeber zur Absicherung der Entgeltansprüche von Welt der
            Wunder TV an diese ab und Welt der Wunder nimmt diese Abtretung
            an.<br />
            Soweit die abgetretenen Ansprüche 150% der abzusichernden
            Entgeltansprüche übersteigen, ist die Agentur berechtigt, von Welt
            der Wunder eine Freigabe der Sicherungsrechte bis zu der
            vorgenannten Schwelle zu verlangen. Welt der Wunder darf bei einer
            Mehrheit von abgetretenen Ansprüchen aus eigenem Ermessen
            entscheiden, welche abgetretenen Ansprüche freigegeben werden.
          </li>
          <li>
            Eine Übertragung von Werbeleistungen durch den Kunden auf einen
            Dritten ist nur mit ausdrücklicher vorheriger schriftlicher
            Zustimmung von Welt der Wunder zulässig.<br />
            Gleiches gilt für Fälle, in denen Waren, Dienstleistungen oder
            Sonstiges durch mehrere Unternehmen in einem Werbemittel beworben
            werden („Verbundwerbung“). In diesem Fall sind durch den Kunden
            sämtliche Werbetreibenden namentlich zu benennen. Welt der Wunder
            ist zur Erhebung eines Verbundzuschlages in Höhe von 20% (zwanzig
            Prozent) bei zwei Werbetreibenden bzw. in Höhe von 30% (dreißig
            Prozent) bei drei oder mehr Werbetreibenden berechtigt. Klarstellend
            halten die Parteien fest, dass der Verbundaufschlag auf die gesamte
            für den Einsatz des Werbemittels vereinbarte Vergütung erhoben wird.
            Vergütungsschuldner für die gesamte Vergütung ist derjenige, mit dem
            der Vertrag ist nur derjenige, mit dem der Vertrag über die
            Erbringung der Verbundwerbung geschlossen wird.
          </li>
          <li>
            Welt der Wunder gewährt einen Abzug in Höhe von 50% (50 Prozent) für
            die Ausstrahlung eines OTC-Pflichthinweises im Sinne von $4 (3) HWG
            (Heilmittelwerbegesetz) bei Werbung für Pharmaprodukte. Dies gilt
            nur für Fälle in denen der Pflichthinweis dem von BAH (Bundesverband
            der Arzneimittel-Hersteller) bzw. dem OWM (Organisation
            Werbungtreibende im Markenverband) empfohlenen Standard (grauer
            Hintergrund, weißer Text, Länge 4 Sekunden) entspricht.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Besondere Bestimmungen für Fernsehwerbung
        <ol>
          <li>
            Arten von Fernsehwerbung
            <ol>
              <li>
                „Werbespot“ ist ein mindestens 5sekündiger Film, indem eine Ware
                oder eine Dienstleistung innerhalb einer Werbezeit im
                Fernsehprogramm beworben wird.
              </li>
              <li>
                „Product Placement“ ist die Erwähnung oder Darstellung von
                Waren, Dienstleistungen, Namen, Marken, Tätigkeiten (sämtliche
                jeweils im Folgenden „Produkt“ oder „Produkte“ genannt) eines
                Herstellers oder Erbringers von Produkten in der Produktion mit
                dem Ziel der Absatzförderung.
              </li>
              <li>
                „Sonderwerbeform“ ist jede Werbeform, die kein Werbespot und
                kein Product Placement ist.
              </li>
            </ol>
          </li>
          <li>
            Lieferung von Material für Werbespots und Sonderwerbeformen
            <ol>
              <li>
                Der Kunde hat das für die Ausstrahlung benötigte Material,
                einschließlich Motivplänen und Sendekopien) in einer technisch
                einwandfreien Form, entsprechend den technischen Anforderungen
                von Welt der Wunder, spätestens 7 Werktage (Montag bis Freitag
                ausschließlich Samstag, Sonntag und gesetzlicher Feiertag in
                Bayern) vor dem geplanten Einsatztermin während der
                Geschäftszeiten von Welt der Wunder (zwischen 09:00 Uhr und
                18:00 Uhr) zur Verfügung zu stellen.
              </li>
              <li>
                Soweit das für die Ausstrahlung benötigte Material
                offensichtliche Mängel oder Schäden aufweist, wird Welt der
                Wunder den Kunden hierüber informieren und der Kunde hat
                unverzüglich unbeschädigtes und mangelfreies Material
                anzuliefern. Welt der Wunder ist nicht verpflichtet, das für die
                Ausstrahlung benötigte Material zu prüfen und zurückzuweisen.
                Aus einer unterbliebenen Prüfung und / oder Zurückweisung können
                keine Rechte hergeleitet werden.
              </li>
              <li>
                Werbespots im SD Format werden aufgrund des Sendeformates im HD
                Fernsehen (1080i/25) zunächst in HD Auflösung konvertiert. Bei
                der Aussendung über SD- Übertragungswege (z.B. Satellit SD) wird
                das HD Signal auf ein SD-Format konvertiert.
              </li>
              <li>
                Kann eine Ausstrahlung mit einem verbindlich vereinbarten
                Einsatztermin nicht erfolgen, weil der Kunde kein
                ausstrahlungsfähiges Material (insbesondere weil das Material
                offensichtlich mangelhaft oder beschädigt ist) innerhalb einer
                Frist nach Ziffer 3.2.1. geliefert hat, so wird Welt der Wunder
                von seiner Leistungspflicht befreit, ohne den Vergütungsanspruch
                zu verlieren. Etwaige anderweitige Einnahmen sind von dem
                Vergütungsanspruch in Abzug zu bringen.
              </li>
            </ol>
          </li>
          <li>
            Lieferung von Produkten zum Product Placement
            <ol>
              <li>
                Der Kunde ist verpflichtet, das bzw. die zu platzierenden
                Produkte in mangelfreier Form zur Verfügung zu stellen und an
                den vereinbarten Ort zu liefern.
              </li>
              <li>
                Die Programmplanung obliegt allein dem Programmveranstalter, der
                den Einsatztermin der Produktion jederzeit aus sendetechnischen
                Gründen verlegen und/oder die Sendung der Produktion absagen
                kann. Im Falle einer Verlegung des Einsatztermines und/oder der
                vollständigen Absage des Einsatztermines sind jegliche Ansprüche
                des Vertragspartners gegen den Programmveranstalter, gleich aus
                welchem Rechtsgrund, ausgeschlossen.
              </li>
              <li>
                Lieferungen des Produktes erfolgen auf Kosten und Risiko des
                Kunden. Der Kunde hat für die Einhaltung sämtlicher
                Transportvorschriften und die Einholung sämtlicher Import- und
                Exportgenehmigungen selbst zu sorgen. Gleiches gilt für den
                Abtransport, soweit das Produkt nicht verbraucht oder an einen
                neuen Eigentümer übereignet wird.
              </li>
              <li>
                Der Kunde stellt Welt der Wunder sowie die
                Produktionsunternehmen – im Sinne eines echten Vertrages
                zugunsten Dritter – auf erstes Anfordern von sämtlichen Schäden,
                Kosten (einschließlich angemessener Kosten der
                Rechtsverteidigung) und Ansprüchen Dritter frei, die durch
                Mängel des Produktes verursacht werden.
              </li>
              <li>
                Der Kunde wird auf Anforderung von Welt der Wunder eine
                angemessene Versicherung für das Produkt bereitstellen. Die
                Kosten der Versicherung trägt der Kunde.
              </li>
            </ol>
          </li>
          <li>
            Ausstrahlungen von Sendungen, in denen Produkte platziert werden
            <ol>
              <li>
                Bezüglich der Verschiebung von Einsatzterminen bei Product
                Placement gelten die Ziffern 5.4. bis 5.6.. Sind keine
                Preisgruppen definiert, gilt die Sendezeit und der Wochentag als
                „Preisgruppe“.
              </li>
              <li>
                Welt der Wunder TV gewährt bei Product Placement, auch innerhalb
                einer Sendung, keinen Konkurrenzschutz.
              </li>
            </ol>
          </li>
          <li>
            Ausstrahlung von Sendungen, in denen Sonderwerbeformen erfolgen
            <ol>
              <li>
                Bezüglich der Verschiebung von Ausstrahlungsterminen für
                Sonderwerbeformen, insbesondere bei Sendungen, in denen durch
                den Kunden oder Welt der Wunder TV im Auftrag des Kunden Geld-
                oder Sachpreise ausgelobt werden, gelten die Ziffern 5.4. bis
                5.6. Sind keine Preisgruppen definiert, gelten Sendezeit und
                Wochentag als „Preisgruppe“.
              </li>
              <li>
                Welt der Wunder TV gewährt bei Sonderwerbeformen, insbesondere
                bei Gewinnspielen, auch innerhalb einer Sendung, keinen
                Konkurrenzschutz.
              </li>
              <li>
                Soweit die Erhebung von Verbraucherdaten, z.B. bei
                Gewinnspielen, durch Welt der Wunder im Auftrag des Kunden
                erfolgt, werden die Gewinnerdaten unverzüglich an den Kunden
                weitergeleitet und der Kunde ist allein für die Abwicklung
                etwaiger Gewinnzusagen verantwortlich und wird Welt der Wunder -
                im Sinne eines echten Vertrages zugunsten Dritter – von
                Ansprüchen Dritter wegen der Durchführung von Gewinnspielen oder
                sonstigen Auslobungen freistellen. Erfolgt das Gewinnspiel oder
                die Auslobung durch Welt der Wunder im eigenen Namen, werden die
                Parteien einzelvertragliche Regelungen zur Abwicklung treffen.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Besondere Bestimmungen für Online-Werbung
        <ol>
          <li>
            Beauftragungen von Online-Werbung benötigen grundsätzlich mindestens
            einen Vorlauf von 10 Werktagen, soweit die Werbemittel sonstige
            besondere Funktionen enthalten. Für sonstige Online-Werbung genügt
            ein Vorlauf von 7 Werktagen.
          </li>
          <li>
            Die Lieferung der Werbemittel erfolgt durch den Kunden, Welt der
            Wunder benötigt mindestens 5 Arbeitstage nach Lieferung für
            Werbemittel im Format Universal Advertising Package (UAP) für die
            Prüfung und Integration, soweit das Werbemittel den sonstigen
            Spezifikationen entspricht. Gleiches gilt für Werbemittel im Rich
            Media Format
          </li>
          <li>
            Solange nicht ein Werbemittel in einer Form angeliefert wird die den
            Anforderungen von Ziffer 4.4 genügt, liegt eine Nichtlieferung vor.
            Im Falle einer Nichtlieferung wird Welt der Wunder von seiner
            Leistungspflicht befreit, ohne den Vergütungsanspruch zu verlieren;
            etwaige anderweitige Einnahmen sind von dem Vergütungsanspruch in
            Anzug zu bringen. Wenn der Kunde verpflichtet ist, mehrere
            Werbemittel anzuliefern, und für eines oder mehrere Werbemittel eine
            Nichtlieferung im Sinne von Satz 1 vorliegt, wird sich Welt der
            Wunder bemühen, en Auftrag mit den gelieferten Werbemitteln
            durchzuführen, behält aber in jedem Fall den vollständigen
            Vergütungsanspruch; etwaige anderweite Einnahmen sind von dem
            Vergütungsanspruch in Abzug zu bringen. Wenn der Kunde ein sog. „Ad
            Bundle“ beauftragt liegt bei Nichtlieferung eines oder mehrerer
            Werbemittel eine Nichtlieferung vor.
          </li>
          <li>
            Das Werbemittel ist in Übereinstimmung mit den zu diesem Zeitpunkt
            geltenden technischen Spezifikationen für Online-Werbung sowie der
            Selbstregulierung der Telemedienanbieter im Bereich
            nutzungsbasierter Online-Werbung zu übersenden.
          </li>
          <li>
            Falls ein Werbemittel verspätet angeliefert wird, reduzieren sich
            die vereinbarten A Impressions (Aufrufe es jeweiligen Werbemittels),
            Referrals (Weiterleitung von Nutzern, die das jeweilige Werbemittel
            angeklickt haben) bzw. sonstigen KPIs (Key Performance Indications)
            zeitanteilig im Verhältnis zur gesamten Kampagnenlaufzeit. Welt der
            Wunder behält den vollständigen Vergütungsanspruch; etwaige
            anderweite Einnahmen sind von dem Vergütungsanspruch in Abzug zu
            bringen.
          </li>
          <li>
            Für Werbemittel, die über Server eingespielt werden soll, die nicht
            von Welt der Wunder betrieben werden (Third Party Server Ads“),
            gelten die folgenden zusätzlichen Bedingungen:
            <ol class="ol-latin">
              <li>
                Aufträge mit Thor Party Server Ads benötigen grundsätzlich
                mindestens einen Vorlauf von 10 Werktagen
              </li>
              <li>
                Im Rahmen einer solchen Buchung sind sämtliche relevanten
                Dokumentationen und Informationen zu übergeben, die für eine
                Einbindung dieser Third Party Server Ads auf technischer Ebene
                notwendig oder hilfreich sind.
              </li>
              <li>
                Vor der Freigabe ist Welt der Wunder nichtverpflichtet, Third
                Party Server As anzuliefern. Third Party Server As dürfen nur
                nach einer Freigabe durch Welt der Wunder nicht mehr ohne
                ausdrückliche Zustimmung von Welt der Wunder verändert werden.
                Es sind darüber hinaus die vereinbarten technischen Standards,
                Übertragungsgeschwindigkeiten und Datenvolumina durch den Kunden
                sicherzustellen.
              </li>
              <li>
                Er Kunde hat sicherzustellen, dass der Betreiber der Server für
                die Auslieferung der Third Party Server Ads mindestens die im
                Rahmen des Einzelvertrages bezeichneten statistischen
                Informationen zur Verfügung stellt, die für die Abrechnung und
                Überprüfung der Leistungserbringung notwenig sind.
              </li>
            </ol>
          </li>
          <li>
            Soweit nicht anders vereinbart, wird Welt der Wunder monatlich über
            die Auslieferung der Werbemittel berichten und abrechnen. Dazu
            werden ausschließlich Impressionen und Click-Rate (wie oft wurde mit
            einem Werbemittel interagiert, z.B. darauf klickt) als Maßstab
            verwendet und dem Kunden mitgeteilt, soweit nichts anderes
            vereinbart wird.
          </li>
          <li>
            Der Kunde hat bei Beauftragung der in diesen Geschäftsbedingungen
            beschriebenen Leistungen vorab im Detail mitzuteilen, welche Daten
            von den Nutzern durch den Kunden oder in seinem Auftrag durch Dritte
            erhoben und verarbeitet werden. Gleiches gilt für die Weitergabe
            oder Verarbeitung an bzw. durch Dritte. Soweit nicht ausdrücklich
            anders vereinbart, ist die Erhebung von personenbezogenen Daten
            allein durch die Anzeige des Werbemittels bzw. durch einen Click auf
            das Werbemittel untersagt. Der Kunde verpflichtet sich außerdem,
            rechtzeitig vor dem Einsatztermin Welt der Wunder die folgenden
            Angaben mitzuteilen, auch wenn dabei keinerlei personenbezogene
            Daten erhoben werden:
            <ol class="ol-latin">
              <li>
                jegliche Inhalte oder Technologien, die durch den Einsatz eines
                Werbemittels (wobei der Einsatz eines Werbemittels mit dem Abruf
                dieses Werbemittels durch weltderwunder.de beginnt und erst dann
                abgeschlossen ist wenn der Nutzer nach Klick auf dieses
                Werbemittel auf der Ziel-Website landet und alle Elemente dieser
                Website vollständig geladen worden und aktiv sind)
                bereitgestellt und geladen werden, insbesondere Server-Cookies
                oder andere Methoden, um einen Nutzer zu kennzeichnen und/oder
                zu markieren.
              </li>
              <li>
                Kopien sämtlicher Mitteilungen und/oder Nachrichten, die ein
                Nutzer bei Interaktion mit einem Werbemittel wahrnehmen kann.
                Damit sind auch Mitteilungen und Nachrichten gemeint, die
                angezeigt werden, wenn das Werbemittel nicht korrekt
                funktioniert oder Meldungen des Internet-Browsers, die den
                Nutzer fragen, ob er dem Setzen eines Server-Cookies zustimmt.
              </li>
            </ol>
          </li>
          <li>
            Soweit Welt der Wunder der Erhebung personenbezogener Daten
            zustimmt, sichert der Kunde zu und steht dafür ein, personenbezogene
            Daten nur in Übereinstimmung mit gesetzlichen Vorschriften zu
            sammeln. Insbesondere sichert der Kunde zu und steht dafür ein, den
            Einsatz von Tracking-Technologien und das Setzen von Cookies nur in
            Übereinstimmung mit gesetzlichen Vorschriften vorzunehmen. Der Kunde
            sichert zu und steht dafür ein, auf sämtlichen Websites (inkl.
            Landing Page), auf die ein Werbemittel verlinkt, eine
            Datenschutzerklärung bereitzustellen, die den gesetzlichen
            Vorschriften entspricht. Der Kunde sichert zu und steht dafür ein,
            Nutzern die Möglichkeit zu geben, die Website, auf die das
            Werbemittel verlinkt ohne die Bereitstellung personenbezogener Daten
            nutzen zu können.
          </li>
          <li>
            Der Kunde hat selbstständig die Werbemittel zu prüfen und
            insbesondere sicher zu stellen, dass die Welt der Wunder zur
            Verfügung gestellten Links auf die Websites des Kunden funktionieren
            und diese Websites verfügbar sind. Welt der Wunder ist zu einer
            solchen Prüfung nicht verpflichtet, die Qualität eines Werbemittels,
            z.B. hinsichtlich der Farbgebung, er Vollständigkeit von
            Animationen, Audioausgaben oder Ähnlichem zu überprüfen.
          </li>
          <li>
            Soweit der Kunde der Ansicht ist, dass das Werbemittel nicht korrekt
            dargestellt wird oder wie beabsichtigt funktioniert, hat der Kunde
            Welt der Wunderhierüber unverzüglich, spätestens aber innerhalb von
            drei Tagen nach dem Start er Kampagne zu informieren, soweit der
            Fehler schon bei Kampagnenstart vorhanden war. Andernfalls hat der
            Kunde Welt der Wunder unverzüglich nach Kenntniserlangen eines
            Fehlers zu informieren. Soweit Welt der Wunder diese Fehler nicht
            verursacht hat, ist Welt der Wunder berechtigt, etwaige Anpassungen
            von einer Kostenübernahme durch den Kunden basieren auf der
            aktuellen Preisliste abhängig zu machen.
          </li>
          <li>
            Soweit zwischen den Parteien Streit über die Vollständigkeit oder
            Richtigkeit der statistischen Informationen besteht, gelten die von
            Welt der Wunder selbst erhobenen Daten hinsichtlich der Ad
            Impressions und Click-Rate als verbindlich für Abrechnungen.
          </li>
          <li>
            Soweit eine bestimmte Anzahl an Ad Impressions über die Dauer der
            Kampagne vereinbart worden ist, wird Welt der Wunder sich angemessen
            bemühen, diese Ad Impressions zu erreichen, Welt der Wunder ist
            mangels anderer Absprachen berechtigt, die konkrete Platzierung und
            Rotation er Werbemittel selbst zu bestimmen.
          </li>
          <li>
            Wird die vereinbarte Anzahl der Ad Impressions während der Laufzeit
            der Kampagne nicht erreicht, ist Welt der Wunder berechtigt, soweit
            der Kunde nichts Gegenteiliges verlangt, ohne zusätzliche Vergütung
            die Werbemittel über die Laufzeit der Kampagne hinaus zu verbreiten,
            bis die vereinbarten Ad Impressions erreicht sind.
          </li>
          <li>
            In keinem Fall hat der Kunde Anspruch auf Minderung der Vergütung
            wegen fehlender Ad Impressions.
          </li>
          <li>
            Wenn nach Beauftragung der in diesen Geschäftsbedingungen
            beschriebenen Leistungen Werbemittel durch den Kunden geändert
            werden (auch Re-direct etc.) oder wenn durch den Kunden die Daten
            nachträglich verändert werden, auf die ein Werbemittel verlinkt, ist
            Welt der Wunder berechtigt, die weitere Ausführung des Auftrages zu
            unterbrechen. Welt der Wunder wird die weitere Ausführung des
            Auftrages wieder aufnehmen, sobald der Kunde die Änderung rückgängig
            gemacht hat. Nach Wahl des Kunden werden die zwischen Änderung und
            Unterbrechung erbrachten Leistungen auf die vereinbarten Ad
            Impressions, Referrals bzw. sonstigen KPIs angerechnet, oder Welt
            der Wunder wird bei entsprechender Mehrvergütung die Leistung bei
            den zum Zeitpunkt der Änderung erbrachten A Impressions, Referrals
            bzw. sonstigen KPIs fortführen. Der Kunde ist in jedem Fall
            verpflichtet, die zwischen Änderung und Unterbrechung erbrachten
            Leistungen zu vergüten.
          </li>
          <li>
            Bei Gewinnspielen generierte Verbraucherdaten gehören ausschließlich
            Welt der Wunder. Der Kunde ist für die Bereitstellung und Lieferung
            der Gewinnspielpreise verantwortlich. Die Lieferung der
            Gewinnspielpreise muss spätestens vier Wochen vor Beendigung des
            Gewinnspiels erfolgen.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Einsatz von Werbemitteln
        <ol>
          <li>
            Mit jeder Zurverfügungstellung von Werbemitteln hat der Kunde
            gleichzeitig sämtliche Angaben mitzuliefern, die für die Abrechnung
            gegenüber Verwertungsgesellschaften wie z.B. der GEMA erforderlich
            sind, insbesondere etwaige Verlage, Komponisten, Produzenten, Titel
            und Längen von eventuell verwendeten Musikstücken.
          </li>
          <li>
            Angaben zu Ausstrahlungs- bzw. Platzierungszeiten, URLs,
            Werbeflächen, Terminen, technischer Aussteuerung von Online-Werbung
            oder Werbeblöcken sind, soweit nicht ausdrücklich zugesichert,
            unverbindlich und unterliegen der Hoheit von Welt der Wunder. Es
            handelt sich insofern um unverbindliche Planungsvorgaben. Welt der
            Wunder wird sich bemühen, etwaige Wünsche er Kunden hinsichtlich der
            Einsatzzeiten zu berücksichtigen.
          </li>
          <li>
            Soweit nicht verbindlich Ausstrahlungs- bzw. Platzierungszeiten,
            URLs, Werbeflächen, Termine oder Werbeblöcke vereinbart wurden, wird
            Welt der Wunder das Werbemittel innerhalb der gebuchten Preisgruppe
            einsetzen und kann insbesondere die Ausstrahlungszeiten, URLs und
            Werbeflächen jederzeit innerhalb einer bestimmten Preisgruppe
            variieren. Welt der Wunder wird den Kunden hierüber unterrichten.
          </li>
          <li>
            Soweit verbindlich Ausstrahlungs- bzw. Platzierungszeiten, URLs,
            Werbeflächen, Termine, technische Aussteuerung von Online-Werbung
            oder Werbeblöcke vereinbart wurden, bedarf die Verschiebung
            grundsätzlich der vorherigen Zustimmung des Kunden.
          </li>
          <li>
            Die Zustimmung gemäß 5.4. ist bei nur geringfügigen und dem Kunden
            zumutbaren Verschiebungen entbehrlich. Die Verschiebung ist
            insbesondere geringfügig, wenn sie innerhalb der gleichen
            Preisgruppe erfolgt und sie zu keiner wesentlichen Abweichung von
            dem ursprünglich vorgesehenen Zeitpunkt führt.
          </li>
          <li>
            Die Zustimmung gemäß 5.4. ist außerdem entbehrlich, wenn Welt der
            Wunder den vorgesehenen Programmablauf eines Senders oder den
            vereinbarten Einsatztermin wegen aktueller Geschehnisse, aus
            ernsthaften, von Welt der Wunder nicht verschuldeten, technischen
            Gründen, wegen höherer Gewalt, Streik oder gesetzlicher Bestimmungen
            ändert. Eine Verschiebung erfolgt nur innerhalb der gleichen
            Preisgruppe. Welt der Wunder wird en Kunden hierüber informieren.
          </li>
          <li>
            Die in den Verkaufsunterlagen ausgewiesenen Programmschemata sind
            nicht abschließend und Welt der Wunder ist berechtigt, weitere
            Werbeblöcke und Werbeflächen anzubieten.
          </li>
          <li>
            Konkurrenzschutz wird, auch innerhalb einzelner Werbeblöcke und
            innerhalb einer einzelnen Website, nicht gewährt.
          </li>
          <li>
            Welt der Wunder wird dem Kunden monatlich einen Einsatznachweis zur
            Verfügung stellen, soweit ein Einsatz erfolgt ist. Dies kann auch
            durch Bereitstellung von Protokollen in einem elektronischen
            Buchungssystem erfolgen.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Prüfung und Ablehnung von Werbemitteln
        <ol>
          <li>
            Welt der Wunder ist nicht verpflichtet, die Werbemittel vor dem
            Vertragsschluss anzusehen oder inhaltlich, rechtlich oder unter
            sonstigen Gesichtspunkten zu prüfen.
          </li>
          <li>
            Welt der Wunder behält sich vor und nach einer Beauftragung für die
            Erbringung der in diesen Geschäftsbedingungen beschriebenen
            Leistungen vor, den Einsatz ganz oder teilweise abzulehnen, wenn
            Welt der Wunder nach einer den Umständen angemessenen Beurteilung
            der Ansicht sind, dass das Werbemittel gegen gesetzliche oder
            behördliche Bestimmungen, insbesondere auch gegen die jeweils
            geltenden gemeinsamen Werberichtlinien der Landesmedienanstalten,
            Rechte Ritter oder die guten Sitten verstößt und nicht den
            technischen und inhaltlichen Anforderungen dieser
            Geschäftsbedingungen entspricht. Eine jede Ablehnung von
            Werbemitteln ist dem Kunden unverzüglich anzuzeigen.
          </li>
          <li>
            Sofern das Werbemittel lediglich für die Einsatztermine der
            gebuchten Preisgruppe ungeeignet ist, kann diese Werbung mit
            Zustimmung des Kunden in einer anderen Preisgruppe ausgestrahlt
            werden. Verweigert der Kunde die Zustimmung, ist Welt der Wunder
            berechtigt, vom Vertrag zurückzutreten.
          </li>
          <li>
            Sofern das Werbemittel von Welt der Wunder gemäß Ziffer 6.2.
            abgelehnt wurde und nicht Ziffer 6.3. einschlägig ist, ist der Kunde
            verpflichtet, innerhalb einer von Welt der Wunder gesetzten,
            angemessenen Nachfrist ein Werbemittel zu liefern, die den
            gesetzlichen oder behördlichen Bestimmungen sowie den technischen
            und inhaltlichen Anforderungen dieser Geschäftsbedingungen
            entspricht.
          </li>
          <li>
            Liefert der Kunde nicht innerhalb der vorgenannten angemessenen
            Nachfrist nach Anzeige der Ablehnung ein Werbemittel, das den
            gesetzlichen oder behördlichen Bestimmungen sowie den technischen
            und inhaltlichen Anforderungen dieser Geschäftsbedingungen
            entspricht, so ist Welt der Wunder zum Rücktritt vom Vertrag
            berechtigt. Hat der Kunden en Rücktritt zu verschulden, ist er zum
            Ersatz des entstandenen Schadens, insbesondere der vereinbarten
            Vergütung unter Anrechnung anderweitiger Einnahmen an Stelle der
            gebuchten Werbung, verpflichtet.
          </li>
          <li>
            Sollte sich herausstellen, dass das Werbemittel den gesetzlichen
            oder behördlichen Bestimmungen sowie den technischen und
            inhaltlichen Anforderungen dieser Geschäftsbedingungen entspricht
            und hat Welt der Wunder dies zum Zeitpunkt der Entscheidung nur
            fahrlässig falsch beurteilt, so ist Welt der Wunder berechtigt, eine
            andere Ausstrahlung oder Platzierung innerhalb der gleichen
            Preisgruppe festzulegen und dies dem Kunden mitzuteilen.
            Widerspricht der Kunde der anderen Ausstrahlung oder Platzierung,
            sind beide Parteien zum Rücktritt berechtigt, ohne dass eine Partei
            zur Leistung von Schadensersatz verpflichtet wäre.
          </li>
          <li>
            Erfolgt die Zurückweisung durch Welt der Wunder aua Gründen, die er
            Kunde nicht zu vertreten hat, so kann der Kunden vom Vertrag
            zurücktreten und die Erstattung etwaiger Vorauszahlungen für die
            konkrete Ausstrahlung oder Platzierung verlangen.
          </li>
          <li>
            Klarstellen halten die Parteien fest, dass die Vorschriften dieses
            Abschnitts nur gelten, wenn der Kunde das Werbemittel vor Ablauf der
            in diesen Geschäftsbedingungen genannten Lieferfristen geliefert
            hat. Sollte der Kunde das Werbemittel erst nach Ablauf dieser
            Lieferfristen geliefert haben, gehen die Vorschriften zur
            Zuspätlieferung in den Abschnitten „Fernsehwerbung“ und
            „Online-Werbung“ vor. Soweit der Kunde im Fall des 5.4. das
            Werbemittel vor Ablauf der angemessenen Nachfrist geliefert hat,
            jedoch die Neulieferung nach Ablauf er in diesen
            Geschäftsbedingungen genannten Lieferfrosten erfolgt ist, ist die
            gesetzte Nachfrist die maßgebliche.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Beschwerden Dritter über eine Werbung
        <ol>
          <li>
            Sollten Dritte gegenüber Welt der Wunder oder gegenüber dem Kunden
            gelten machen, das ein Werbemittel gegen gesetzliche oder
            behördliche Bestimmungen, insbesondere auch gegen die jeweils
            geltenden gemeinsamen Werberichtlinien und Landesmedienanstalten,
            Rechte Ritter oder die guten Sitten verstoße oder nicht den
            technischen und inhaltlichen Anforderungen dieser
            Geschäftsbedingungen entspreche (insgesamt „Verfahren“),
            verpflichtet sich der Kunde, Welt der Wunder unverzüglich unter
            Nennung aller Details und Übersendung der Korrespondenz zu
            informiere, ebenso gegenüber Welt der Wunder eine Stellungnahme
            anzugeben, wie er die Angelegenheit einschätzt, ob er bereits in der
            Vergangenheit mit vergleichbaren Sachverhalten zu tun hatte uns wie
            er seinerzeit damit umgegangen ist und weiterhin eine Erklärung
            abzugeben, wie er dem Verfahren begegnen möchte.
          </li>
          <li>
            Die Vertragsparteien verpflichten sich, unverzüglich Kontakt
            aufzunehmen und sich über das weitere Vorgehen auszutauschen. Die
            Vertragsparteien werden sich weiterhin darüber informieren, wer in
            welcher Form dem Verfahren begegnet.
          </li>
          <li>
            Der Kunde wir Welt der Wunder alle nach Treu und Glauben zum
            Austausch und zur Entscheidung über das weitere Vorgehen
            erforderlichen Informationen auf eine Kosten zur Verfügung stellen.
          </li>
          <li>
            Welt der Wunder übernimmt keine Haftung dafür, dass ein von Welt der
            Wunder vorgeschlagenes Vorgehen und/oder eine von Welt der Wunder
            abgegebene rechtliche Bewertung rechtmäßig, angemessen und/oder
            erfolgreich ist.
          </li>
          <li>
            Der Kunde verpflichtet sich, vor einem erfolgten Austausch weder auf
            das Verfahren zu antworten noch sonst in dieser Sache mit der
            Gegenseite in Kontakt zu treten, Erklärungen abzugeben und/oder
            Zugeständnisse zu machen.
          </li>
          <li>
            Abweichend von 6.2. übernimmt Welt der Wunder die voll Kontrolle
            über das Verfahren, soweit sich das Verfahren auf Welt der Wunder
            zugeordnete geistige Schutzrechte bezieht
          </li>
          <li>
            Sollte das Ergebnis des Verfahrens sein, dass das Werbemittel gegen
            gesetzliche oder behördliche Bestimmungen, insbesondere auch gegen
            die jeweils geltenden gemeinsamen Werberichtlinien der
            Landesmedienanstalten, Rechte Dritter oder die guten Sitten verstößt
            oder nicht den technischen und inhaltlichen Anforderungen dieser
            Geschäftsbedingungen entspricht, gelten die Regelungen 5.2.-5.5.
            entsprechend. Ergebnis des Verfahrens im Sinne dieser Vorschrift ist
            jede gegen Welt der Wunder oder den Kunden (auch vorläufig)
            vollstreckbare Entscheidung eines Gerichts oder einer Behörde oder
            einer Einrichtung der freiwilligen Selbstkontrolle. Gleiches gilt,
            wenn Welt der Wunder aufgrund der drohenden Nachteile ein Zuwarten
            einer Entscheidung nicht zuzumuten ist; dies ist insbesondere dann
            der Fall soweit Welt der Wunder eigene, finanzielle Nachteile
            drohen.
          </li>
          <li>
            Welt der Wunder ist ab Eingang des Verfahrens bei Welt der Wunder
            bzw. ab Information des Kunden über ein Verfahren jederzeit
            berechtigt, die weitere Ausstrahlung bzw. Platzierung des
            Werbemittels auszusetzen.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Gewährleistung
        <ol>
          <li>
            Die Werbeleistung durch Welt der Wunder ist durch den Kunden
            unverzüglich zu prüfen und offensichtliche Mängel sin unverzüglich
            zu rügen. Nicht offensichtliche Mängel sind innerhalb von 2 Wochen
            anzuzeigen. Erfolgt eine solche Anzeige nicht fristgemäß, erlöschen
            die Ansprüche wegen des Mangels, mit Ausnahme von
            Schadensersatzansprüchen, soweit der Schaden grob fahrlässig oder
            vorsätzlich durch Welt der Wunder oder seine Erfüllungsgehilfen
            verursacht wurde.
          </li>
          <li>
            Welt der Wunder ist zunächst zur Nachbesserung berechtigt, soweit
            dies dem Kunden zumutbar ist. Darüberhinausgehende Ansprüche stehen
            dem Kunden nur zu, wenn die Nachbesserung nicht innerhalb
            angemessener Frist erfolgt.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Umbuchung,Kündigung
        <ol>
          <li>
            Beide Parteien sind berechtigt, den Auftrag für die Erbringung der
            in diesen Geschäftsbedingungen beschriebenen Leistungen ganz oder
            teilweise bis zu 6 Wochen vor dem ersten Einsatztermin ohne Angabe
            von Gründen zu kündigen. Im Fall einer teilweisen Kündigung erhöht
            sich der Preis nach den Preislisten von Welt der Wunder, soweit der
            Kunde zuvor eine Mengenrabattierung erhalten hat und durch die
            Kündigung die entsprechende Rabattstufe nicht mehr erreicht wird.
            Diese Möglichkeit der Kündigung gilt nicht für den Kunden bei
            Werbespots mit einer Dauer von mehr als 90 Sekunden. Daneben ist der
            Kunde berechtigt, bis zu 10 Werktage vor dem Einsatz von
            Werbemitteln den Einsatztermin nach Verfügbarkeit bei Welt der
            Wunder umzubuchen.
          </li>
          <li>
            Soweit der Kunde nach Ablauf der Frist von 7 Wochen Welt der Wunder
            mitteilt, dass er einen Auftrag nicht mehr wünscht, so wird Welt der
            Wunder darauf hinwirken, dass unter Aufrechterhaltung des
            ordentlichen Betriebsablaufes die Erbringung der beauftragten
            Leistung unterbleibt. Der Vergütungsanspruch seitens Welt er Wunder
            bleibt in diesem Fall bestehen.
          </li>
          <li>
            Die vorstehenden Regelungen gelten nicht für Sonderwerbeformen oder
            Product Placement.
          </li>
          <li>
            Die Möglichkeit zur Kündigung aus wichtigem Grund bleibt unberührt.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Vergütung
        <ol>
          <li>
            Soweit nicht ausdrücklich eine Vergütung vereinbart ist, gelten die
            bei Abschluss des Vertrages gültigen Preislisten bei Welt der
            Wunder.
          </li>
          <li>
            Alle Preise verstehen sich in Euro und zuzüglich gesetzlicher
            Mehrwertsteuer. Sie erhalten keine Kosten für die Produktion von
            Werbemitteln, diese werden gesondert berechnet.
          </li>
          <li>
            Der Kunde trägt eine etwa anfallende urheber- bzw.
            leistungsschutzrechtliche Vergütung, die wegen er ausgestrahlten
            bzw. platzierten Werbemittel an Verwertungsgesellschaften zu zahlen
            ist und stellt Welt der Wunder (im Sinne eines echten Vertrages
            zugunsten Dritter) von diesen Ansprüchen auf erstes Anfordern frei.
          </li>
          <li>
            Welt der Wunder ist jederzeit berechtigt, die Preise für die
            Erbringung der in diesen Geschäftsbedingungen beschriebenen
            Leistungen zu ändern. Sofern nicht ausdrücklich etwas Anderes
            vereinbart ist, treten neue Preise auch für laufenden Aufträge
            sofort in Kraft. Im Falle einer Preiserhöhung ist der Kunde
            innerhalb von 3 Tagen nach entsprechender Mitteilung durch Welt der
            Wunder berechtigt, den Auftrag umzubuchen oder schriftlich vom
            Auftrag zurück zu treten.
          </li>
          <li>
            Welt der Wunder räumt dem Kunden Rabatte gemäß der bei
            Vertragsabschluss geltenden Preislisten ein. Darüberhinausgehende
            Rabatte sind einzelvertraglich zu vereinbaren.
          </li>
          <li>
            Welt der Wunder behält sich vor, Agenturen einen Rabatt in Höhe von
            15% des Rechnungsbetrages (ohne Mehrwertsteuer) nach Abzug sonstiger
            Rabatte, jedoch vor Anzug von Skonto, zu gewähren. Ein Anspruch
            hierauf besteht in Ermangelung einer einzelvertraglichen
            Vereinbarung nicht.
          </li>
          <li>
            Soweit die Parteien Rabatte für mit dem Kunden verbundene
            Unternehmen vereinbaren, so entfällt ein solcher Rabatt mangels
            abweichender Vereinbarung, sobald das verbundene Unternehmen nicht
            mehr im mehrheitlichen Besitz (über 50% der Anteile) des Kunden
            steht oder soweit der Kunde nicht im mehrheitlichen Besitz (über 50%
            der Anteile) des verbundenen Unternehmens steht. Daraus entstehende
            Nachforderungen hat der Kunde unverzüglich zu begleichen. Der Kunde
            ist verpflichtet, Welt der Wunder über Änderungen der
            Beteiligungsverhältnisse zu berichten, soweit diese zum Wegfall des
            Rabattes führen würden.
          </li>
          <li>
            Die Vergütung für den Einsatz von Werbemitteln erfolgt monatlich im
            Voraus. Bei Zahlungseingang innerhalb von 10 Tagen ab Rechnungsdatum
            wird 2% Skonto gewährt, jedoch nur soweit die Zahlung mindestens 3
            Werktage vor dem ersten Einsatz erfolgt und der Kunde sich mit
            anderen Zahlungen nicht im Verzug befindet.
          </li>
          <li>
            Der Kunde gerät, ohne dass es einer Mahnung bedürfte, in Verzug,
            soweit die Vergütung nicht innerhalb von 14 Tagen nach Zugang der
            Rechnung geleistet wird. Der Kunde hat etwaige Kosten des
            Zahlungsverkehrs oder Kosten wegen Rückbuchungen oder ähnlichen
            Ereignissen zu tragen.
          </li>
          <li>
            Welt der Wunder ist berechtigt, die vertragsgemäße Leistung
            zurückzuhalten, soweit der Kunde nicht spätestens 3 Werktage vor dem
            Einsatz die fällige Vergütung beglichen hat oder sich im Verzug
            befindet. Dies gilt nicht, soweit der Kunde die Aufrechnung mit
            unstreitigen oder gerichtlich festgestellten Forderungen erklärt
            oder soweit dem Kunden selbst ein Zurückbehaltungsrecht zusteht und
            er dieses spätestens 3 Werktage vor dem Einsatz geltend macht.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Nutzungsrechte
        <ol>
          <li>
            Der Kunde räumt Welt der Wunder bei Vertragsabschluss das
            unentgeltliche nichtausschließliche Recht zur vertragsgemäßen
            Nutzung der Werbemittel ein. Welt der Wunder ist berechtigt, die
            eingeräumten Rechte Erfüllungsgehilfen und Dritten zu übertragen,
            die mit der Vertragserfüllung befasst sind.
          </li>
          <li>
            Bei Fernsehwerbung wird insbesondere das Senderecht für die gesamten
            jeweiligen Sendegebiete eingeräumt, und zwar ohne Einschränkung
            hinsichtlich der verwendeten Übertragungstechnik, Übertragungswege
            und Verbreitungsmedien, wie z.B. Satellit, terrestrische
            Übertragung, Kabelübertragung einschließlich Kabelweitersendung und
            der notwenigen Sublizenzrechte für die weiterverbreitenden
            Kabelunternehmen, sowie das Recht der öffentlichen
            Zugänglichmachung, insbesondere mittels des Internet Protocols,
            unabhängig von der verswendeten Übertragungstechnik – einschließlich
            UMTS, LTE, DSL, Glasfaser – und der zur Wahrnehmung verwendeten
            Endgeräte – einschließlich TV, Spielkonsolen, Computer, Laptops,
            Tablets, Mobiltelefone – einschließlich des zeitgleichen oder
            zeitversetzten Streamens oder Downloads gegen ein Entgelt oder
            kostenlos und unter Einbindung anderer Werbemittel als in der
            Fernsehübertragung. Soweit das Werbemittel innerhalb von TV
            Programmen auch im Rahmen von Video-on-Demand oder anderen
            Abruf-Angeboten seitens Welt der Wunder öffentlich zugänglich
            gemacht wird ist dieses Recht mitumfasst, insbesondere für den Fall
            von Werbung bei Vorab-Veröffentlichungen. Ausgenommen sind hier
            lediglich die der GEMA un GVL eingeräumten Aufführungsrechte für die
            Sendung einschließlich programmbegleitender Online-Nutzung. Der
            Kunde hat jedoch die Befugnis zur Nutzung der Musiken im
            Zusammenhang mit dem Werbemittel (Verfilmungsrecht bzw. master und
            synch rights) auf eigene Verantwortung und Kosten mit den Inhabern
            der Nutzungsrechte zu klären und ggf. auf Verlangen von Welt der
            Wunder in geeigneter Form nachzuweisen. Die Rechteübertragung
            erfolgt zeitlich, örtlich und inhaltlich in dem für die Durchführung
            des Auftrages erforderlichen Umfang. Dies umfasst auch das Recht zur
            Bearbeitung der Werbemittel, soweit dies aus technischen Gründen
            notwendig ist. Sowohl das Sendesignal als auch Abruf-Angebote können
            aufgrund der technischen Gegebenheiten auch außerhalb der
            Bundesrepublik Deutschland empfangen bzw. abgerufen werden. Welt der
            Wunder ist nicht verpflichtet, seine Sendung zu verschlüsseln oder
            den Zugriff auf Abruf-Angebote regional zu begrenzen.
          </li>
          <li>
            Bei Online-Werbung wird insbesondere das weltweite Recht der
            öffentlichen Zugänglichmachung der Werbemittel eingeräumt, und zwar
            ohne Beschränkung der verwendeten Übertragungstechnik,
            Übertragungswege und Verbreitungsmedien wie z.B. UMTS, LTE, DSL oder
            Glasfaser, und ohne Beschränkung der zur Wahrnehmung verwendeten
            Endgeräte – einschließlich TV, Spielekonsolen, Computer, Laptops,
            Tablets, Mobiltelefone. Die Rechteübertragung erfolgt zeitlich,
            örtlich und inhaltlich in dem für die Durchführung des Auftrages
            erforderlichen Umfang. Dies umfasst auch das Recht zur Bearbeitung
            der Werbemittel soweit dies aus technischen Gründen notwendig ist.
          </li>
          <li>
            Der Kunde sichert zu, über diese Rechte zu verfügen und diese nicht
            anderweitig übertragen zu haben.
          </li>
          <li>
            Der Kunde räumt Welt der Wunder das unentgeltliche
            nicht-ausschließliche Recht ein, eingesetzte Werbemittel für
            Eigenwerbung, Öffentlichkeitsarbeit, Maßnahmen zur Absatzförderung
            oder zur Beratung weiterer Kunden zu verwenden.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Haftung
        <ol>
          <li>
            Im Fall leichter Fahrlässigkeit haften Welt der Wunder, seine
            Mitarbeitet, seine Erfüllungsgehilfen und seine Organe nur für die
            Verletzung von Kardinalpflichten und nur in Höhe des
            vertragsphysischen, vorhersehbaren Aschadens. Diese
            Haftungsbeschränkung gilt nicht für Verletzungen es Lebens, Körpers,
            oder der Gesundheit oder im Fall von Ansprüchen nach dem
            Produkthaftungsgesetz.
          </li>
          <li>
            Kardinalspflichten sin solche Pflicht, deren Erfüllung die
            ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen
            und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und
            vertrauen darf.
          </li>
          <li>
            Der Kunde ist allein dafür verantwortlich und garantiert, dass das
            Werbemittel dem deutschen Recht entspricht und keine Rechte Dritter
            verletzt. Dies gilt nicht, soweit die Rechtsverletzung durch Welt
            der Wunder schulhaft herbeigeführt wurde, z.B. durch eine eigene
            Bearbeitung. Der Kunde stellt Welt der Wunder von jeglichen
            Ansprüchen Dritter aufgrund des Einsatzes des Werbemittels,
            einschließlich der angemessenen Kosten der Rechtsverteidigung auf
            erstes Anfordern frei.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Geheimhaltung
        <ol>
          <li>
            Der Kunde verpflichtet sich, den Inhalt des vorliegenden Vertrages
            mitsamt dieser allgemeinen Geschäftsbedingungen und den Preislisten
            von Welt der Wunder und insbesondere den vereinbarten Konditionen,
            Preisnachlässen und Mediavolumina sowie sonstige aus der
            Zusammenarbeit der Parteien dem Kunden bekannt gewordenen Betriebs-
            und Geschäftsgeheimnisse (insgesamt „Vertrauliche Informationen“)
            streng vertraulich zu behandeln und die vertraulichen Informationen
            Dritten nicht zugänglich zu machen, solange und soweit die
            vertraulichen Informationen nicht allgemein bekannt sin oder werden,
            ohne dass dies durch eine Verletzung der Vertraulichkeitspflicht
            begründet wäre, oder die vertraulichen Informationen aufgrund
            rechtlicher Vorschriften Behörden zugänglich zu machen sind. Die
            Weitergabe vertraulicher Informationen an für den Kunden tätige
            Steuerberater und Anwälte ist zulässig. Diese Verpflichtung besteht
            über die Beendigung des Vertragsverhältnisses hinaus.
          </li>
          <li>
            Abweichend von 13.1. sind Agenturen berechtigt, die vereinbarten
            Konditionen und Mediavolumina ihren Werbekunden offenzulegen, soweit
            das für die Erfüllung der vertraglichen Pflichten der Agentur
            gegenüber ihren Werbekunden erforderlich ist. Vor einer Offenlegung
            muss die Agentur ihre Werbekunden schriftlich darauf verpflichten,
            dass die Werbekunden die vertraulichen Informationen streng
            vertraulich behandeln und sie Dritten nicht zugänglich machen,
            solange und soweit sie nicht allgemein bekannt sind oder werden,
            ohne dass durch eine Verletzung der Vertraulichkeitspflicht
            begründet wäre. Oder die vertraulichen Informationen aufgrund
            rechtlicher Vorschriften Behörden zugänglich zu machen sind, und
            dass diese Verpflichtung über die Beendigung des
            Vertragsverhältnisses hinaus besteht. Auf Verlangen von Welt der
            Wunder hat die Agentur die Verpflichtungserklärung ihrer Werbekunden
            nachzuweisen.
          </li>
        </ol>
      </li>
      <li class="agb-title mt-25">
        Schlussbestimmungen
        <ol>
          <li>
            Die Aufrechnung gegen Ansprüche von Welt der Wunder ist nur mit
            unbestrittenen oder rechtskräftig festgestellten Forderungen
            zulässig. Ein Zurückbehaltungsrecht steht dem Kunden nur aufgrund
            von Forderungen aus dem gleichen Vertragsverhältnis zu.
          </li>
          <li>
            Die Abtretung von Rechten und Ansprüchen aus diesem Rechtsverhältnis
            durch den Kunden bedarf der Zustimmung seitens Welt der Wunder
          </li>
          <li>Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.</li>
          <li>
            Ausschließlicher Gerichtsstand ist München, soweit der Kunde
            Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich
            rechtliches Sondervermögen ist. Welt der Wunder ist berechtigt auch
            am Sitz des Beklagten Ansprüche geltend zu machen.
          </li>
          <li>
            Soweit der Kunde seinen Sitz nicht in der Bundesrepublik Deutschland
            hat gilt ebenfalls München als ausschließlicher Gerichtsstand,
            soweit der Kunde nicht Verbraucher ist.
          </li>
          <li>
            Sollten einzelne oder mehrere Bestimmungen dieser
            Geschäftsbedingungenunwirksam sein oder werden, so wird hiervon die
            Wirksamkeit aller sonstigen Bestimmungen oder Vereinbarungen im
            Zweifel nicht berührt.
          </li>
        </ol>
      </li>
    </ol>
  </AppLayout>
</template>

<script>
import AppLayout from "../components/layouts/AppLayout.vue";

export default {
  components: { AppLayout },
};
</script>

<style lang="scss">
.agb-title {
  margin-bottom: 40px;

  ol {
    margin-top: 15px;
    padding-left: 40px;

    & > li {
      margin-bottom: 20px;
    }
  }
}
</style>
